import styled from "styled-components";


export const FixedLarge404 = styled.div`
  display: block;
  position: fixed;
  width: 90%;
  left: 10%;
  top: -10%;
  z-index: -1;
  color: #303ebb;
`;
