import React from 'react';

import { SmallSubtitlesTypo, SmallSubtitlesTypoP, LargeHeaderTypo, MediumHeaderTypo, NotFound404Typo } from '../commonStyles/typoTheme';
import { FixedTopLogo } from '../commonStyles/FixedTopMenuStyles';
import { SectionHeaderStyle } from '../commonStyles/SectionsStyle';

import { HeaderLine, HeaderMainHeader, HeaderWhatIs } from './styles/HeaderStyles';
import { FixedLarge404 } from './styles/NotFound404Styles';

class SectionHeader extends React.PureComponent {
  render() {
    return (
      <SectionHeaderStyle>
        <FixedLarge404>
          <NotFound404Typo>
            404: Page not found
          </NotFound404Typo>
        </FixedLarge404>
        <FixedTopLogo />
          <HeaderLine>
          </HeaderLine>
          <HeaderMainHeader>
            <LargeHeaderTypo>
              Oops! This page isn't available.
            </LargeHeaderTypo>
            <MediumHeaderTypo>
            The page you were looking for doesn't exist. Go back to our homepage or contact us.
            </MediumHeaderTypo>
          </HeaderMainHeader>
          <HeaderWhatIs>
            <SmallSubtitlesTypoP>
              WE ARE SORRY
            </SmallSubtitlesTypoP>
            <SmallSubtitlesTypo>
              Error 404
            </SmallSubtitlesTypo>
          </HeaderWhatIs>
      </SectionHeaderStyle>
    );
  }
}

export default SectionHeader;
