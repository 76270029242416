import React from "react"

import NotFoundComponent  from '../components/NotFoundComponent';
import GlobalStyle from '../components/layoutCss';

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <>
        <GlobalStyle />
        <NotFoundComponent />
      </>
    )
  }
}

export default NotFoundPage;
