import React from "react"

import MainContainer from '../commonStyles/MainContainer';

import SEO from "../components/seo";
import SectionNotFound  from '../components/SectionNotFound';


class NotFoundComponent extends React.PureComponent {


  render() {
    return (
      <>
        <SEO title='Home' />
        <MainContainer>
          <SectionNotFound />
        </MainContainer>
      </>
    )
  }
}

export default NotFoundComponent;
